import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, unref as _unref, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["id"];
const _hoisted_2 = { class: "container grid-layout md:border-b md:border-black md:pb-20" };
const _hoisted_3 = { class: "col-span-full lg:col-span-1" };
const _hoisted_4 = {
    key: 0,
    class: "border-b border-black pb-10 md:border-none lg:pl-5"
};
const _hoisted_5 = { class: "flex flex-col gap-4" };
const _hoisted_6 = { class: "flex flex-col gap-10" };
import ListItemWithIcon from '@atoms/ListItemWithIcon.vue';
import { hyphenate } from '@helpers/text';
import ButtonWithArrow from '@atoms/ButtonWithArrow.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VisitUsBlock',
    props: {
        openingHours: {
            type: String,
            required: true,
        },
        address: {
            type: String,
            required: true,
        },
        admissionInfo: {
            type: Object,
            required: true,
        },
        tubeLines: {
            type: Object,
            required: true,
        },
        content: {
            type: String,
            required: true,
        },
        anchors: {
            type: Object,
            required: false,
        },
        anchor: {
            type: String,
            required: false,
        },
        pageLinks: {
            type: Object,
            required: false,
        },
    },
    setup(__props) {
        return (_ctx, _cache) => {
            const _directive_dompurify_html = _resolveDirective("dompurify-html");
            return (_openBlock(), _createElementBlock("section", {
                id: __props.anchor,
                class: "!mt-10",
                "data-visit-us-block": ""
            }, [
                _createElementVNode("div", _hoisted_2, [
                    _withDirectives(_createElementVNode("div", _hoisted_3, null, 512), [
                        [_directive_dompurify_html, __props.content]
                    ]),
                    (__props.anchors.length > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createElementVNode("ul", _hoisted_5, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.anchors, (anchor) => {
                                    return (_openBlock(), _createElementBlock("li", {
                                        key: anchor.id
                                    }, [
                                        _createVNode(ButtonWithArrow, {
                                            text: anchor.title,
                                            url: `#${anchor.id}`,
                                            icon: "chevron",
                                            target: "_self"
                                        }, null, 8, ["text", "url"])
                                    ]));
                                }), 128)),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.pageLinks, (link) => {
                                    return (_openBlock(), _createElementBlock("li", {
                                        key: _unref(hyphenate)(link.title)
                                    }, [
                                        _createVNode(ButtonWithArrow, {
                                            target: link.target,
                                            text: link.title,
                                            url: link.url
                                        }, null, 8, ["target", "text", "url"])
                                    ]));
                                }), 128))
                            ])
                        ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("ul", _hoisted_6, [
                        _createVNode(ListItemWithIcon, {
                            content: __props.openingHours,
                            extraClasses: "lg:order-last",
                            icon: "clock",
                            title: "Opening hours"
                        }, null, 8, ["content"]),
                        _createVNode(ListItemWithIcon, {
                            content: __props.admissionInfo.content,
                            title: __props.admissionInfo.title,
                            icon: "ticket"
                        }, null, 8, ["content", "title"]),
                        _createVNode(ListItemWithIcon, {
                            content: __props.address,
                            extraClasses: "lg:order-first",
                            icon: "pin",
                            title: "Address"
                        }, null, 8, ["content"]),
                        _createVNode(ListItemWithIcon, {
                            content: __props.tubeLines.content,
                            title: __props.tubeLines.title,
                            icon: "tube"
                        }, null, 8, ["content", "title"])
                    ])
                ])
            ], 8, _hoisted_1));
        };
    }
});
