import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "container grid grid-cols-3 items-center py-4 lg:py-5" };
const _hoisted_2 = { class: "justify-self-center" };
const _hoisted_3 = { class: "flex items-center gap-6 justify-self-end xl:gap-10" };
const _hoisted_4 = ["href", "target", "rel"];
const _hoisted_5 = { class: "block h-5 w-5" };
import { computed, reactive, defineAsyncComponent } from 'vue';
import { useMq } from 'vue3-mq';
import SiteLogo from '@atoms/SiteLogo.vue';
import SvgIcon from '@atoms/SvgIcon.vue';
import SearchField from '@atoms/SearchField.vue';
import BurgerButton from '@atoms/BurgerButton.vue';
import AnnouncementBar from '@molecules/AnnouncementBar.vue';
import BurgerLines from '@atoms/BurgerLines.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SiteHeader',
    props: {
        primaryNavigation: {
            type: Object,
            required: true,
        },
        secondaryNavigation: {
            type: Object,
            required: true,
        },
        fullScreenNavigation: {
            type: Object,
            required: true,
        },
        japaneseText: {
            type: String,
            required: true,
        },
        japaneseLink: {
            type: Object,
            required: true,
        },
        featuredPost: {
            type: Object,
            required: true,
        },
        announcementBarText: {
            type: String,
            required: true,
        },
        announcementBarLink: {
            type: Object,
            required: true,
        },
        suggestedSearches: {
            type: Object,
            required: false,
        },
    },
    setup(__props) {
        const MobileNavigation = defineAsyncComponent(() => import('@molecules/MobileNavigation.vue'));
        const DesktopFullScreenNavigation = defineAsyncComponent(() => import('@molecules/DesktopFullScreenNavigation.vue'));
        const LeftHeaderNavigation = defineAsyncComponent(() => import('@molecules/LeftHeaderNavigation.vue'));
        const screen = useMq();
        const state = reactive({
            navOpen: false,
            searchOpen: false,
        });
        const eitherStates = computed(() => {
            return state.navOpen || state.searchOpen;
        });
        const props = __props;
        //get nav item where the url matches /shop
        const shopNavItem = computed(() => {
            const navItems = Object.values(props.primaryNavigation); // Get an array of values (nav items) from the object
            return navItems.find((navItem) => navItem.url.includes('shop.japanhouselondon.uk'));
        });
        const openSearchModal = () => {
            state.searchOpen = !state.searchOpen;
            if (state.searchOpen) {
                stopBodyScrolling();
                setTimeout(() => {
                    state.navOpen = false;
                }, 500);
            }
            else {
                closeSearchModal();
            }
        };
        const closeSearchModal = () => {
            state.searchOpen = false;
            startBodyScrolling();
        };
        const openNavigation = () => {
            state.navOpen = !state.navOpen;
            if (state.navOpen) {
                stopBodyScrolling();
            }
            else {
                startBodyScrolling();
            }
        };
        let savedScrollPosition = 0; // Store the scroll position
        //These might want to go into a utility file if they get used again.
        const stopBodyScrolling = () => {
            // Save the current scroll position
            savedScrollPosition = window.scrollY;
            // Disable body scroll
            document.body.style.overflow = 'hidden';
        };
        const startBodyScrolling = () => {
            // Enable body scroll
            document.body.style.overflow = 'auto';
            // Restore the scroll position
            window.scrollTo(0, savedScrollPosition);
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("header", {
                "data-site-header": "",
                id: "site-header",
                class: _normalizeClass([{
                        'min-h-0 bg-white text-black': !eitherStates.value,
                        'min-h-[calc(100dvh+1px)] bg-black text-white lg:min-h-0': eitherStates.value,
                    }, "fixed top-0 z-50 flex w-full flex-col border-b border-grey-100 text-sm transition-all"])
            }, [
                _createElementVNode("div", _hoisted_1, [
                    (_unref(screen).lgPlus)
                        ? (_openBlock(), _createBlock(_unref(LeftHeaderNavigation), {
                            key: 0,
                            "nav-open": eitherStates.value,
                            navigation: __props.primaryNavigation
                        }, null, 8, ["nav-open", "navigation"]))
                        : _createCommentVNode("", true),
                    (_unref(screen).mdMinus)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 1,
                            class: "h-7 w-7 cursor-pointer justify-self-start",
                            onClick: openSearchModal,
                            "aria-label": "Search"
                        }, [
                            _createVNode(SvgIcon, { name: "search" })
                        ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(SiteLogo, { navOpen: eitherStates.value }, null, 8, ["navOpen"])
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                        (!eitherStates.value && shopNavItem.value && _unref(screen).lgPlus)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                href: shopNavItem.value.url,
                                target: shopNavItem.value.target || '_self',
                                rel: shopNavItem.value.target === '_blank'
                                    ? 'noopener noreferrer'
                                    : null,
                                class: "flex items-center gap-2.5 font-bold text-black"
                            }, [
                                _createTextVNode(_toDisplayString(shopNavItem.value.label) + " ", 1),
                                _createElementVNode("span", _hoisted_5, [
                                    _createVNode(SvgIcon, { name: "shopping-bag" })
                                ])
                            ], 8, _hoisted_4))
                            : _createCommentVNode("", true),
                        _createVNode(BurgerButton, {
                            navOpen: state.navOpen,
                            searchOpen: state.searchOpen,
                            onClick: openNavigation
                        }, null, 8, ["navOpen", "searchOpen"]),
                        (_unref(screen).lgPlus && !state.navOpen)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 1,
                                class: _normalizeClass([{
                                        'w-7': state.searchOpen,
                                        'w-5': !state.searchOpen,
                                    }, "h-5 w-5 cursor-pointer overflow-hidden"]),
                                onClick: openSearchModal,
                                "aria-label": "Search"
                            }, [
                                (!state.searchOpen)
                                    ? (_openBlock(), _createBlock(SvgIcon, {
                                        key: 0,
                                        name: "search"
                                    }))
                                    : _createCommentVNode("", true),
                                _createElementVNode("div", {
                                    class: _normalizeClass([{
                                            'relative left-0': state.searchOpen,
                                            'absolute left-full': !state.searchOpen,
                                        }, "top-0 flex h-full w-full cursor-pointer items-center gap-2.5 font-bold"])
                                }, [
                                    _createVNode(BurgerLines, {
                                        active: state.searchOpen
                                    }, null, 8, ["active"])
                                ], 2)
                            ], 2))
                            : _createCommentVNode("", true)
                    ])
                ]),
                (__props.primaryNavigation && _unref(screen).mdMinus)
                    ? (_openBlock(), _createBlock(_unref(MobileNavigation), {
                        key: 0,
                        navOpen: state.navOpen,
                        navigation: __props.primaryNavigation
                    }, null, 8, ["navOpen", "navigation"]))
                    : _createCommentVNode("", true),
                (__props.fullScreenNavigation && _unref(screen).lgPlus)
                    ? (_openBlock(), _createBlock(_unref(DesktopFullScreenNavigation), {
                        key: 1,
                        navOpen: state.navOpen,
                        navigation: __props.fullScreenNavigation,
                        featuredPost: __props.featuredPost,
                        japaneseText: __props.japaneseText,
                        japaneseLink: __props.japaneseLink,
                        secondaryNavigation: __props.secondaryNavigation
                    }, null, 8, ["navOpen", "navigation", "featuredPost", "japaneseText", "japaneseLink", "secondaryNavigation"]))
                    : _createCommentVNode("", true),
                (!state.navOpen && !state.searchOpen)
                    ? (_openBlock(), _createBlock(AnnouncementBar, {
                        key: 2,
                        announcementBarLink: __props.announcementBarLink,
                        announcementBarText: __props.announcementBarText
                    }, null, 8, ["announcementBarLink", "announcementBarText"]))
                    : _createCommentVNode("", true),
                _createVNode(SearchField, {
                    searchOpen: state.searchOpen,
                    suggestedSearches: __props.suggestedSearches,
                    onClose: closeSearchModal
                }, null, 8, ["searchOpen", "suggestedSearches"])
            ], 2));
        };
    }
});
