import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "w-7" };
export default /*@__PURE__*/ _defineComponent({
    __name: 'BurgerLines',
    props: {
        active: {
            type: Boolean,
            required: false,
            default: false,
        },
        black: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
                    return _createElementVNode("span", {
                        key: i,
                        class: _normalizeClass([{
                                'my-1.5': i === 2,
                                '!opacity-0': i === 2 && __props.active,
                                'w-[23px] bg-white first:-rotate-45 last:rotate-45': __props.active,
                                'bg-black': !__props.active,
                                '!bg-black': __props.active && __props.black,
                            }, "block h-0.5 w-7 origin-right transform opacity-100 transition-all ease-in-out"])
                    }, null, 2);
                }), 64))
            ]));
        };
    }
});
