export function useFormTracking() {
    const handleFormBlock = (formBlock) => {
        let isFirstClick = true;
        const validFormTags = new Set(['INPUT', 'TEXTAREA', 'SELECT']);
        const handleClick = (event) => {
            const clickedElement = event.target;
            if (isFirstClick && validFormTags.has(clickedElement.tagName)) {
                const clickedID = clickedElement.id;
                const associatedLabel = document.querySelector(`label[for="${clickedID}"]`);
                const labelText = associatedLabel?.textContent.replace('(Required)', '');
                const formName = formBlock
                    .querySelector('form')
                    ?.getAttribute('name');
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'form',
                    form_name: formName,
                    form_state: 'View',
                    field_name: labelText,
                });
                isFirstClick = false;
            }
        };
        formBlock.addEventListener('click', handleClick);
    };
    return {
        handleFormBlock,
    };
}
