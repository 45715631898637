import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["id", "aria-label", "aria-labelledby", "name"];
const _hoisted_2 = { class: "invisible absolute opacity-0" };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = ["id", "checked", "name", "value"];
const _hoisted_5 = ["for"];
const _hoisted_6 = { class: "flex h-3 w-3 items-center justify-center rounded-full border border-black lg:h-4 lg:w-4" };
import { hyphenate, decodeHTML } from '@helpers/text';
import { computed, ref, watch } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RadioButtons',
    props: {
        options: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
    setup(__props, { emit: __emit }) {
        const hasOptions = computed(() => {
            return props.options && Object.keys(props.options).length > 0;
        });
        const props = __props;
        const ariaLabel = computed(() => `Select a ${props.title}`);
        // Define a reactive variable to hold the selected value
        const selectedValue = ref('');
        const emit = __emit;
        const checkUrlForFilterTerm = (value) => {
            const urlParams = new URLSearchParams(window.location.search);
            const filteredContent = urlParams.get('filtered');
            if (filteredContent) {
                return filteredContent === value;
            }
            // If no filter is set, check if the value is 'all'
            return value === 'all';
        };
        const emitSelectedValue = (event) => {
            // Update the reactive variable with the selected value
            selectedValue.value = event.target.value;
            // Emit an event to the parent component with the selected value
            emit('selectedValueChanged', selectedValue.value);
        };
        const addFilterSelectionToUrl = (selectedValue) => {
            const newUrl = new URL(window.location.href);
            // Remove 'filtered' parameter if 'all' is selected
            if (selectedValue === 'all') {
                newUrl.searchParams.delete('filtered');
            }
            else {
                newUrl.searchParams.set('filtered', selectedValue);
            }
            window.history.pushState({}, '', newUrl.toString());
            window.location.reload();
        };
        watch(selectedValue, (newValue) => {
            addFilterSelectionToUrl(newValue);
        });
        return (_ctx, _cache) => {
            return (hasOptions.value)
                ? (_openBlock(), _createElementBlock("fieldset", {
                    key: 0,
                    id: _unref(hyphenate)(__props.title),
                    "aria-label": ariaLabel.value,
                    "aria-labelledby": _unref(hyphenate)(__props.title),
                    name: _unref(hyphenate)(__props.title),
                    class: "flex w-full justify-start gap-5",
                    onChange: emitSelectedValue
                }, [
                    _createElementVNode("legend", _hoisted_2, " Select a " + _toDisplayString(__props.title) + " to filter the results by: ", 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                            (option.slug)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                    _createElementVNode("input", {
                                        id: option.slug,
                                        checked: checkUrlForFilterTerm(option.slug),
                                        name: option.slug,
                                        value: option.slug,
                                        class: "hidden",
                                        type: "radio"
                                    }, null, 8, _hoisted_4),
                                    _createElementVNode("label", {
                                        for: option.slug,
                                        class: "flex cursor-pointer items-center gap-2 font-bold"
                                    }, [
                                        _createElementVNode("div", _hoisted_6, [
                                            _createElementVNode("span", {
                                                class: _normalizeClass(["h-1.5 w-1.5 rounded-full bg-black lg:h-2 lg:w-2", {
                                                        block: checkUrlForFilterTerm(option.slug),
                                                        hidden: !checkUrlForFilterTerm(option.slug),
                                                    }])
                                            }, null, 2)
                                        ]),
                                        _createTextVNode(" " + _toDisplayString(_unref(decodeHTML)(option.name)), 1)
                                    ], 8, _hoisted_5)
                                ]))
                                : _createCommentVNode("", true)
                        ], 64));
                    }), 256))
                ], 40, _hoisted_1))
                : _createCommentVNode("", true);
        };
    }
});
