import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue";
const _hoisted_1 = { "data-archive-collection": "" };
const _hoisted_2 = { class: "container flex flex-col gap-10 lg:gap-20" };
const _hoisted_3 = {
    key: 0,
    class: "grid-layout"
};
const _hoisted_4 = {
    key: 1,
    class: "text-center"
};
const _hoisted_5 = { "data-quicklinks": "" };
import { defineAsyncComponent } from 'vue';
import PostCard from '@atoms/PostCard.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ArchiveCollection',
    props: {
        posts: {
            type: Object,
            required: false,
        },
        pagination: {
            type: Object,
            required: false,
        },
        quickLinksSections: {
            type: Object,
            required: false,
        },
        filterOptions: {
            type: Object,
            required: false,
        },
    },
    setup(__props) {
        const ArchivePagination = defineAsyncComponent(() => import('@molecules/ArchivePagination.vue'));
        const QuickLinks = defineAsyncComponent(() => import('@molecules/QuickLinks.vue'));
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("section", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        (__props.posts.length > 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.posts, (post) => {
                                    return (_openBlock(), _createBlock(PostCard, {
                                        post: post,
                                        class: "!opacity-100"
                                    }, null, 8, ["post"]));
                                }), 256))
                            ]))
                            : (_openBlock(), _createElementBlock("h3", _hoisted_4, " Sorry, there are no posts to display for your filter selection. Please try another. ")),
                        (__props.pagination.pages.length > 1)
                            ? (_openBlock(), _createBlock(_unref(ArchivePagination), {
                                key: 2,
                                pagination: __props.pagination
                            }, null, 8, ["pagination"]))
                            : _createCommentVNode("", true)
                    ])
                ]),
                (__props.quickLinksSections)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(__props.quickLinksSections, (quickLinks) => {
                        return (_openBlock(), _createElementBlock("section", _hoisted_5, [
                            _createVNode(_unref(QuickLinks), { quickLinks: quickLinks }, null, 8, ["quickLinks"])
                        ]));
                    }), 256))
                    : _createCommentVNode("", true)
            ], 64));
        };
    }
});
