import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import InlineSvg from 'vue-inline-svg';
import { computed } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SiteLogo',
    props: {
        navOpen: {
            type: Boolean,
            required: false,
            default: false,
        },
        sizeXL: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(__props) {
        // Compute the icon source dynamically using a computed property
        const logoSrc = computed(() => {
            return require(`@images/logo.svg`);
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("a", {
                "data-site-logo": "",
                class: _normalizeClass([{
                        '!text-black': !__props.navOpen,
                        '!text-white': __props.navOpen,
                        'h-auto w-24 lg:h-20 lg:w-auto': !__props.sizeXL,
                        'h-20 w-auto': __props.sizeXL,
                    }, "block"]),
                href: "/",
                "aria-label": "Japan House London"
            }, [
                _createVNode(_unref(InlineSvg), {
                    src: logoSrc.value,
                    class: "h-full w-full"
                }, null, 8, ["src"])
            ], 2));
        };
    }
});
