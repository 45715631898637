import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = ["src"];
const _hoisted_2 = {
    key: 0,
    class: "h6-alt mb-2"
};
const _hoisted_3 = { key: 1 };
import SvgIcon from '@atoms/SvgIcon.vue';
import { useMq } from 'vue3-mq';
import { computed } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ListItemWithIcon',
    props: {
        title: {},
        icon: {},
        externalIcon: { type: Boolean },
        content: {},
        extraClasses: {},
        removeDesktopIcons: { type: Boolean }
    },
    setup(__props) {
        const screen = useMq();
        const props = __props;
        const removeDesktopIcons = computed(() => {
            return !!props.removeDesktopIcons && !!screen.lgPlus;
        });
        let formattedContent = props.content;
        // check if content does not contain <p> tags, if not wrap it in <p> tags
        if (props.content && !props.content.includes('<p>')) {
            formattedContent = `<p>${props.content}</p>`;
        }
        return (_ctx, _cache) => {
            const _directive_dompurify_html = _resolveDirective("dompurify-html");
            return (_openBlock(), _createElementBlock("li", {
                class: _normalizeClass([_ctx.extraClasses, "flex basis-1/3 items-start gap-4"])
            }, [
                (!removeDesktopIcons.value)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass([
                            'mt-1 size-3.5 shrink-0 grow-0',
                            _ctx.externalIcon ? 'flex items-center' : '',
                        ])
                    }, [
                        (_ctx.externalIcon)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                alt: "",
                                "aria-hidden": "true",
                                src: _ctx.icon,
                                class: "block h-auto w-full"
                            }, null, 8, _hoisted_1))
                            : (_openBlock(), _createBlock(SvgIcon, {
                                key: 1,
                                name: _ctx.icon
                            }, null, 8, ["name"]))
                    ], 2))
                    : _createCommentVNode("", true),
                _createElementVNode("div", null, [
                    (_ctx.title)
                        ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.title), 1))
                        : _createCommentVNode("", true),
                    (_unref(formattedContent))
                        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, null, 512)), [
                            [_directive_dompurify_html, _unref(formattedContent)]
                        ])
                        : _createCommentVNode("", true)
                ])
            ], 2));
        };
    }
});
