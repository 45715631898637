import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "container flex items-center justify-between gap-4 overflow-hidden" };
const _hoisted_2 = { class: "flex flex-wrap gap-2 text-xs md:text-sm" };
import ButtonWithArrow from '@atoms/ButtonWithArrow.vue';
import { computed, nextTick, reactive, ref, watchEffect } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AnnouncementBar',
    props: {
        announcementBarText: {
            type: String,
            required: false,
        },
        announcementBarLink: {
            type: Object,
            required: false,
        },
    },
    setup(__props) {
        const props = __props;
        const showAnnouncementBarLink = computed(() => {
            return props.announcementBarLink && props.announcementBarLink.url;
        });
        const state = reactive({
            announcementOpen: true,
        });
        const closeAnnouncement = () => {
            state.announcementOpen = false;
            sessionStorage.setItem('announcementClosed', true);
            const main = document.getElementById('main');
            main.style.paddingTop = '0px';
        };
        const isAnnouncementClosed = ref(sessionStorage.getItem('announcementClosed') === 'true');
        if (isAnnouncementClosed.value) {
            state.announcementOpen = false;
        }
        // Watcher to monitor changes in the announcementOpen state
        watchEffect(() => {
            if (state.announcementOpen) {
                nextTick(() => {
                    const main = document.getElementById('main');
                    const announcementBar = document.getElementById('announcementBar');
                    if (main && announcementBar) {
                        main.style.paddingTop = `${announcementBar.offsetHeight}px`;
                    }
                });
            }
        });
        return (_ctx, _cache) => {
            return (__props.announcementBarText && !isAnnouncementClosed.value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    "data-announcement-bar": "",
                    id: "announcementBar",
                    class: _normalizeClass([
                        state.announcementOpen ? 'grid-rows-1 py-4' : 'grid-rows-0 py-0',
                        "grid grid-cols-1 bg-black text-white transition-all"
                    ])
                }, [
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("div", _hoisted_2, [
                            _createTextVNode(_toDisplayString(__props.announcementBarText) + " ", 1),
                            (showAnnouncementBarLink.value)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _cache[0] || (_cache[0] = _createElementVNode("span", { class: "hidden lg:block" }, "-", -1)),
                                    _createVNode(ButtonWithArrow, {
                                        class: "text-xs !text-white md:text-sm",
                                        target: __props.announcementBarLink.target,
                                        text: __props.announcementBarLink.title,
                                        url: __props.announcementBarLink.url
                                    }, null, 8, ["target", "text", "url"])
                                ], 64))
                                : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", null, [
                            _createElementVNode("button", {
                                class: "relative flex h-7 w-7 cursor-pointer items-center justify-center !text-white",
                                onClick: closeAnnouncement,
                                "aria-label": "Close the announcement bar"
                            }, [
                                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (i) => {
                                    return _createElementVNode("span", {
                                        key: i,
                                        class: "absolute block h-0.5 w-[23px] shrink-0 grow-0 origin-center transform bg-white opacity-100 transition-all ease-in-out first:-rotate-45 last:rotate-45"
                                    });
                                }), 64))
                            ])
                        ])
                    ])
                ], 2))
                : _createCommentVNode("", true);
        };
    }
});
