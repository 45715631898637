import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    key: 0,
    "data-featured-posts": ""
};
const _hoisted_2 = { class: "container flex items-center justify-between" };
const _hoisted_3 = { class: "h3 mb-2.5" };
import PostCarousel from '@molecules/PostCarousel.vue';
import { useMq } from 'vue3-mq';
import { hyphenate } from '@helpers/text';
import { computed, defineAsyncComponent } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FeaturedPosts',
    props: {
        title: {
            type: String,
            required: false,
            default: 'Featured Posts',
        },
        featuredPosts: {
            type: Object,
            required: false,
        },
    },
    setup(__props) {
        const screen = useMq();
        const SwiperNavigation = defineAsyncComponent(() => import('@molecules/SwiperNavigation.vue'));
        const displaySwiperNavigation = computed(() => {
            return screen.lgPlus && Object.keys(props.featuredPosts).length > 3;
        });
        const props = __props;
        const titleForCarousel = computed(() => {
            return hyphenate(props.title) + '-carousel';
        });
        const hasFeaturedPosts = computed(() => {
            return props.featuredPosts && Object.keys(props.featuredPosts).length > 0;
        });
        return (_ctx, _cache) => {
            return (hasFeaturedPosts.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("h2", _hoisted_3, _toDisplayString(__props.title), 1),
                        (displaySwiperNavigation.value)
                            ? (_openBlock(), _createBlock(_unref(SwiperNavigation), {
                                key: 0,
                                carouselTitle: titleForCarousel.value
                            }, null, 8, ["carouselTitle"]))
                            : _createCommentVNode("", true)
                    ]),
                    _createVNode(PostCarousel, {
                        carouselTitle: titleForCarousel.value,
                        posts: __props.featuredPosts,
                        lazyLoadImages: "eager"
                    }, null, 8, ["carouselTitle", "posts"])
                ]))
                : _createCommentVNode("", true);
        };
    }
});
