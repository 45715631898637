import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "container flex items-center justify-between gap-6 pb-2 lg:pb-3.5" };
const _hoisted_2 = { class: "mb-0" };
const _hoisted_3 = { class: "flex gap-12" };
import ButtonWithArrow from '@atoms/ButtonWithArrow.vue';
import PostCarousel from '@molecules/PostCarousel.vue';
import { hyphenate } from '@helpers/text';
import { defineAsyncComponent } from 'vue';
import { useMq } from 'vue3-mq';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CollectionCarousel',
    props: {
        collection: {
            type: Object,
            required: true,
        },
        index: {
            type: String,
            required: true,
        },
    },
    setup(__props) {
        const SwiperNavigation = defineAsyncComponent(() => import('@molecules/SwiperNavigation.vue'));
        const screen = useMq();
        const props = __props;
        const carouselTitle = hyphenate(props.collection.title) + '-collection-carousel';
        return (_ctx, _cache) => {
            const _directive_dompurify_html = _resolveDirective("dompurify-html");
            return (_openBlock(), _createElementBlock("section", {
                "data-collection-carousel": "",
                class: _normalizeClass([{
                        'bg-blue-50': __props.index % 2 === 0,
                    }, "!my-0 pb-20 pt-6 lg:py-10"])
            }, [
                _createElementVNode("div", _hoisted_1, [
                    _withDirectives(_createElementVNode("h2", _hoisted_2, null, 512), [
                        [_directive_dompurify_html, __props.collection.title]
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                        (_unref(screen).lgPlus)
                            ? (_openBlock(), _createBlock(_unref(SwiperNavigation), {
                                key: 0,
                                carouselTitle: carouselTitle
                            }))
                            : _createCommentVNode("", true),
                        _createVNode(ButtonWithArrow, {
                            url: __props.collection.url,
                            text: "View all"
                        }, null, 8, ["url"])
                    ])
                ]),
                (__props.collection.posts)
                    ? (_openBlock(), _createBlock(PostCarousel, {
                        key: 0,
                        posts: __props.collection.posts,
                        carouselTitle: carouselTitle
                    }, null, 8, ["posts"]))
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
