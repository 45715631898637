import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "group"
};
const _hoisted_2 = ["for"];
const _hoisted_3 = ["id", "aria-label", "aria-labelledby", "name"];
const _hoisted_4 = {
    disabled: "",
    selected: "",
    value: ""
};
const _hoisted_5 = ["selected", "value"];
const _hoisted_6 = {
    key: 1,
    class: "flex flex-wrap gap-2"
};
const _hoisted_7 = ["value"];
const _hoisted_8 = {
    key: 0,
    class: "pointer-events-none relative block"
};
import { hyphenate, decodeHTML } from '@helpers/text';
import { computed, ref } from 'vue';
import { useMq } from 'vue3-mq';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FilterInput',
    props: {
        options: {},
        placeholder: {}
    },
    setup(__props, { emit: __emit }) {
        const props = __props;
        const screen = useMq();
        const ariaLabel = computed(() => props.placeholder);
        // Define a reactive variable to hold the selected value
        const selectedValue = ref('');
        const emit = __emit;
        const checkUrlForFilterTerm = (value) => {
            const urlParams = new URLSearchParams(window.location.search);
            const filteredContent = urlParams.get('filtered');
            if (filteredContent) {
                return filteredContent === value;
            }
        };
        const emitSelectedValue = (event) => {
            if (screen.lgPlus) {
                // Remove 'filtered' parameter if existing filter item is selected
                if (checkUrlForFilterTerm(event.target.value)) {
                    return emit('selectedValueChanged', 'clear-filter');
                }
            }
            // Update the reactive variable with the selected value
            selectedValue.value = event.target.value;
            // Emit an event to the parent component with the selected value
            return emit('selectedValueChanged', selectedValue.value);
        };
        return (_ctx, _cache) => {
            return (_unref(screen).mdMinus)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("label", {
                        for: _unref(hyphenate)(_ctx.placeholder),
                        class: "invisible absolute opacity-0"
                    }, _toDisplayString(_ctx.placeholder), 9, _hoisted_2),
                    _createElementVNode("select", {
                        id: _unref(hyphenate)(_ctx.placeholder),
                        "aria-label": ariaLabel.value,
                        "aria-labelledby": _unref(hyphenate)(_ctx.placeholder),
                        name: _unref(hyphenate)(_ctx.placeholder),
                        class: "filter",
                        onChange: emitSelectedValue
                    }, [
                        _createElementVNode("option", _hoisted_4, _toDisplayString(_ctx.placeholder), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                            return (_openBlock(), _createElementBlock(_Fragment, null, [
                                (option.slug)
                                    ? (_openBlock(), _createElementBlock("option", {
                                        key: 0,
                                        selected: checkUrlForFilterTerm(option.slug),
                                        value: option.slug
                                    }, _toDisplayString(_unref(decodeHTML)(option.name)), 9, _hoisted_5))
                                    : _createCommentVNode("", true)
                            ], 64));
                        }), 256)),
                        _cache[0] || (_cache[0] = _createElementVNode("option", { value: "clear-filter" }, "Clear filter", -1))
                    ], 40, _hoisted_3)
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                        return (_openBlock(), _createElementBlock("button", {
                            onClick: _withModifiers(emitSelectedValue, ["prevent"]),
                            value: option.slug,
                            key: option.slug,
                            class: _normalizeClass([
                                'flex items-center gap-2 border px-2 py-1 transition-colors hover:border-green-50 hover:bg-green-50 hover:text-black',
                                checkUrlForFilterTerm(option.slug)
                                    ? 'border-2 border-green-50 pr-6 font-bold'
                                    : 'border-grey-75',
                            ])
                        }, [
                            _createTextVNode(_toDisplayString(option.name) + " ", 1),
                            (checkUrlForFilterTerm(option.slug))
                                ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (i) => {
                                        return _createElementVNode("span", {
                                            key: i,
                                            class: "absolute block h-px w-3.5 shrink-0 grow-0 origin-center transform bg-black opacity-100 transition-all ease-in-out first:-rotate-45 last:rotate-45"
                                        });
                                    }), 64))
                                ]))
                                : _createCommentVNode("", true)
                        ], 10, _hoisted_7));
                    }), 128))
                ]));
        };
    }
});
