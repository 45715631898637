import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    "data-post-carousel": "",
    class: "container !px-0 pt-3 md:!px-3 lg:!px-8 lg:pt-5 xl:!px-10"
};
import { register } from 'swiper/element';
import { Navigation } from 'swiper/modules';
import { computed, defineAsyncComponent, onMounted, ref } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PostCarousel',
    props: {
        posts: {
            type: Object,
            required: true,
        },
        carouselTitle: {
            type: String,
            required: true,
        },
        lazyLoadImages: {
            type: String,
            required: false,
            default: 'lazy',
        },
    },
    setup(__props) {
        const PostCard = defineAsyncComponent(() => import('@atoms/PostCard.vue'));
        register();
        const config = computed(() => ({
            modules: [Navigation],
            lazy: true,
            loop: false,
            slidesPerView: 1.25,
            breakpoints: {
                720: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                    slidesOffsetBefore: 0,
                    slidesPerGroup: 2,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                    slidesOffsetBefore: 0,
                    slidesPerGroup: 3,
                },
            },
            slidesOffsetAfter: 16,
            slidesOffsetBefore: 16,
            spaceBetween: 10,
            speed: 500,
            slidesPerGroup: 1,
        }));
        const swiper = ref(null);
        const initSwiper = () => {
            Object.assign(swiper.value, config.value);
            swiper.value.initialize();
        };
        onMounted(() => initSwiper());
        return (_ctx, _cache) => {
            const _component_swiper_slide = _resolveComponent("swiper-slide");
            const _component_swiper_container = _resolveComponent("swiper-container");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_swiper_container, {
                    ref_key: "swiper",
                    ref: swiper,
                    "data-carousel": __props.carouselTitle,
                    class: "swiper-container",
                    init: false,
                    options: config.value
                }, {
                    default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.posts, (post) => {
                            return (_openBlock(), _createBlock(_component_swiper_slide, {
                                key: post.id
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_unref(PostCard), {
                                        lazyLoadImage: __props.lazyLoadImages,
                                        post: post
                                    }, null, 8, ["lazyLoadImage", "post"])
                                ]),
                                _: 2
                            }, 1024));
                        }), 128))
                    ]),
                    _: 1
                }, 8, ["data-carousel", "options"])
            ]));
        };
    }
});
