import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, createVNode as _createVNode, createTextVNode as _createTextVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "flex flex-col items-end" };
const _hoisted_2 = {
    key: 1,
    class: "mb-4 flex w-full items-end border-b border-white py-5 lg:mb-0"
};
const _hoisted_3 = { class: "grow" };
const _hoisted_4 = { class: "h-5 w-[35px] shrink-0 grow-0 overflow-hidden" };
const _hoisted_5 = {
    key: 2,
    class: "block w-full py-20"
};
const _hoisted_6 = { class: "flex w-full flex-wrap gap-x-10 gap-y-5" };
import { ref } from 'vue';
import SvgIcon from 'scripts/components/atoms/SvgIcon.vue';
import { useMq } from 'vue3-mq';
import ButtonWithArrow from '@atoms/ButtonWithArrow.vue';
import { convertToSearchTerm, convertToTitleCase } from '@helpers/text';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SearchField',
    props: {
        searchOpen: {
            type: Boolean,
            required: false,
            default: false,
        },
        suggestedSearches: {
            type: Object,
            required: false,
        },
    },
    setup(__props, { emit: __emit }) {
        const screen = useMq();
        const searchQuery = ref('');
        const performSearch = () => {
            // Redirect to the WordPress search results page with the search query.
            if (searchQuery.value.trim() !== '') {
                // Modify the URL to match your WordPress search results page.
                const searchResultsPageURL = '/search/';
                const queryParam = searchQuery.value
                    .trim()
                    .toLowerCase()
                    .replace(' ', '+');
                // Redirect to the search results page.
                window.location.href = searchResultsPageURL + queryParam;
            }
        };
        const emit = __emit;
        const emitCloseEvent = () => {
            emit('close');
            searchQuery.value = '';
        };
        const stopPropagation = (event) => {
            // Prevent the click event from propagating to the parent.
            event.stopPropagation();
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                "data-search-field": "",
                class: _normalizeClass([{ 'grid-rows-1': __props.searchOpen }, "fixed left-0 top-0 z-50 grid w-full shrink grid-cols-1 grid-rows-0 bg-black text-white transition-all lg:relative lg:max-h-[calc(100vh-120px)] lg:bg-transparent"])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass([{ 'overflow-y-auto !delay-0 lg:pt-14': __props.searchOpen }, "container overflow-hidden transition-[padding] delay-100"]),
                    onClick: stopPropagation
                }, [
                    _createElementVNode("div", _hoisted_1, [
                        _createVNode(_TransitionGroup, {
                            "enter-active-class": "slide-up__enter-active",
                            "enter-from-class": "slide-up__enter-from",
                            "enter-to-class": "slide-up__enter-to",
                            "leave-active-class": "slide-up__enter-active",
                            "leave-from-class": "slide-up__enter-to",
                            "leave-to-class": "slide-up__enter-from"
                        }, {
                            default: _withCtx(() => [
                                (__props.searchOpen && _unref(screen).mdMinus)
                                    ? (_openBlock(), _createElementBlock("button", {
                                        key: 0,
                                        class: "relative mt-4 flex h-7 w-7 cursor-pointer items-center justify-center",
                                        onClick: emitCloseEvent
                                    }, [
                                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (i) => {
                                            return _createElementVNode("span", {
                                                key: i,
                                                class: "absolute block h-0.5 w-[23px] shrink-0 grow-0 origin-center transform bg-white opacity-100 transition-all ease-in-out first:-rotate-45 last:rotate-45"
                                            });
                                        }), 64))
                                    ]))
                                    : _createCommentVNode("", true),
                                (__props.searchOpen)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                        _createElementVNode("div", _hoisted_3, [
                                            _cache[1] || (_cache[1] = _createElementVNode("label", { class: "h3 !mb-5 block" }, " Search ", -1)),
                                            _withDirectives(_createElementVNode("input", {
                                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((searchQuery).value = $event)),
                                                class: "block w-full bg-black text-white placeholder:text-grey-200 focus:appearance-none focus:border-none focus:outline-none",
                                                placeholder: "Type here.",
                                                type: "search",
                                                onKeyup: _withKeys(performSearch, ["enter"])
                                            }, null, 544), [
                                                [_vModelText, searchQuery.value]
                                            ])
                                        ]),
                                        _createElementVNode("button", {
                                            class: "flex shrink-0 items-center gap-1 text-sm font-bold text-white",
                                            onClick: performSearch
                                        }, [
                                            _cache[2] || (_cache[2] = _createTextVNode(" Submit ")),
                                            _createElementVNode("span", _hoisted_4, [
                                                _createVNode(SvgIcon, { name: "arrow" })
                                            ])
                                        ])
                                    ]))
                                    : _createCommentVNode("", true),
                                (__props.searchOpen && _unref(screen).lgPlus && __props.suggestedSearches)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                        _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "border-b border-white pb-8" }, " Suggested searches ", -1)),
                                        _createElementVNode("ul", _hoisted_6, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.suggestedSearches, (searchTerm) => {
                                                return (_openBlock(), _createElementBlock("li", null, [
                                                    _createVNode(ButtonWithArrow, {
                                                        text: _unref(convertToTitleCase)(searchTerm.query),
                                                        url: `/search/${_unref(convertToSearchTerm)(searchTerm.query)}`,
                                                        class: "!text-white"
                                                    }, null, 8, ["text", "url"])
                                                ]));
                                            }), 256))
                                        ])
                                    ]))
                                    : _createCommentVNode("", true)
                            ]),
                            _: 1
                        })
                    ])
                ], 2)
            ], 2));
        };
    }
});
