import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
    class: "postCard",
    "data-post-card": ""
};
const _hoisted_2 = ["href", "title", "target", "rel"];
const _hoisted_3 = {
    key: 0,
    class: "text-sm font-bold leading-none"
};
const _hoisted_4 = {
    key: 1,
    class: "flex items-center gap-1.5 text-sm leading-none"
};
const _hoisted_5 = { class: "size-3" };
const _hoisted_6 = {
    key: 0,
    class: "h2 lg:h3 !mb-0 !mt-0"
};
const _hoisted_7 = {
    key: 0,
    class: "block text-base"
};
const _hoisted_8 = {
    key: 1,
    class: "mt-2.5 flex flex-col gap-4 border-t border-black pt-5 lg:mt-3 lg:pt-6"
};
const _hoisted_9 = {
    key: 0,
    class: "size-3"
};
const _hoisted_10 = { key: 1 };
import { computed, defineAsyncComponent } from 'vue';
import { decodeHTML } from '@helpers/text';
import ImageLandscape from '@atoms/ImageLandscape.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PostCard',
    props: {
        post: {},
        lazyLoadImage: { default: 'lazy' }
    },
    setup(__props) {
        const SvgIcon = defineAsyncComponent(() => import('@atoms/SvgIcon.vue'));
        const props = __props;
        const showBookingStatus = computed(() => !!props.post?.is_event && !!props.post?.is_bookable_event);
        const displayComingSoon = computed(() => {
            return props.post?.is_coming_soon;
        });
        const displaySoldOut = computed(() => {
            return props.post?.is_sold_out && !props.post?.is_past_event;
        });
        const isExternalEvent = computed(() => {
            return props.post?.is_external_event;
        });
        const externalEventShortInfoLine = computed(() => {
            return props.post?.external_event_short_info_line;
        });
        const displayExternalEventShortInfoLine = computed(() => {
            return isExternalEvent.value && externalEventShortInfoLine.value.length > 0;
        });
        const waitlistAvailable = computed(() => {
            return props.post?.waitlist_available;
        });
        const cardInfoString = computed(() => {
            if (displaySoldOut.value && waitlistAvailable.value) {
                return { icon: 'ticket', text: 'Fully booked – join waiting list' };
            }
            if (displaySoldOut.value) {
                return { icon: 'ticket', text: 'Fully booked' };
            }
            if (displayComingSoon.value) {
                return { icon: 'ticket', text: 'Booking opening soon' };
            }
            return { icon: 'ticket', text: 'Book now' };
        });
        const eventDetails = computed(() => {
            const details = [];
            if (props.post.date_range) {
                details.push({ icon: 'calendar', text: props.post.date_range });
            }
            if (props.post.event_location) {
                details.push({
                    icon: 'pin',
                    text: props.post.event_location,
                });
            }
            if (props.post.is_online) {
                details.push({ icon: 'wifi', text: 'Online' });
            }
            if (displayExternalEventShortInfoLine.value) {
                details.push({
                    icon: 'information',
                    text: externalEventShortInfoLine.value,
                });
            }
            return details;
        });
        //  Get content_type and event_type from post.terms
        const filterForTypesTaxonomies = props.post?.terms?.filter((term) => term.taxonomy === 'event_type' || term.taxonomy === 'content_type');
        const typesTaxArray = filterForTypesTaxonomies.map((type) => type.name);
        const typesTaxString = typesTaxArray.join(', ');
        const cardHeaderClasses = computed(() => {
            return [
                'mb-5 flex items-center gap-4 border-b border-black pb-5 lg:pb-6',
                typesTaxArray.length > 0 ? 'justify-between' : 'justify-end',
            ].join(' ');
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("article", _hoisted_1, [
                _createElementVNode("a", {
                    href: _ctx.post.url,
                    title: `Read more about ${_ctx.post.title}`,
                    class: "group flex scroll-ml-3 flex-col gap-7",
                    target: _ctx.post.target,
                    rel: _ctx.post.target === '_blank' ? 'noopener noreferrer' : null
                }, [
                    (_ctx.post.image.url)
                        ? (_openBlock(), _createBlock(ImageLandscape, {
                            key: 0,
                            image: _ctx.post.image,
                            lazyLoadImage: _ctx.lazyLoadImage
                        }, null, 8, ["image", "lazyLoadImage"]))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", null, [
                        _createElementVNode("div", {
                            class: _normalizeClass(cardHeaderClasses.value)
                        }, [
                            (_unref(typesTaxString))
                                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(typesTaxString)), 1))
                                : _createCommentVNode("", true),
                            (showBookingStatus.value)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                                    _createElementVNode("span", _hoisted_5, [
                                        _createVNode(_unref(SvgIcon), {
                                            name: cardInfoString.value.icon
                                        }, null, 8, ["name"])
                                    ]),
                                    _createTextVNode(" " + _toDisplayString(cardInfoString.value.text), 1)
                                ]))
                                : _createCommentVNode("", true)
                        ], 2),
                        (_ctx.post.title)
                            ? (_openBlock(), _createElementBlock("h3", _hoisted_6, [
                                _createTextVNode(_toDisplayString(_unref(decodeHTML)(_ctx.post.title)) + " ", 1),
                                (_ctx.post.subtitle)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(decodeHTML)(_ctx.post.subtitle)), 1))
                                    : _createCommentVNode("", true)
                            ]))
                            : _createCommentVNode("", true),
                        (eventDetails.value.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(eventDetails.value, (detail, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                        key: index,
                                        class: "flex items-center gap-5"
                                    }, [
                                        (detail.icon)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                                                _createVNode(_unref(SvgIcon), {
                                                    name: detail.icon
                                                }, null, 8, ["name"])
                                            ]))
                                            : _createCommentVNode("", true),
                                        (detail.text)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(detail.text), 1))
                                            : _createCommentVNode("", true)
                                    ]));
                                }), 128))
                            ]))
                            : _createCommentVNode("", true)
                    ])
                ], 8, _hoisted_2)
            ]));
        };
    }
});
