import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = ["srcset"];
const _hoisted_3 = ["alt", "src", "loading"];
import { computed } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ImageLandscape',
    props: {
        image: {},
        extraClasses: {},
        forcedRatio: { type: Boolean, default: true },
        allowCaption: { type: Boolean, default: false },
        lazyLoadImage: { default: 'lazy' },
        isChildPageHeader: { type: Boolean, default: false },
        imageClasses: { default: '' }
    },
    setup(__props) {
        const props = __props;
        const hasImage = computed(() => {
            return props.image?.url?.length > 0;
        });
        const displayCaption = computed(() => {
            return props.image?.caption && props.allowCaption;
        });
        const imageClasses = computed(() => {
            let classes = [];
            if (props.imageClasses) {
                classes.push(props.imageClasses);
            }
            if (props.forcedRatio) {
                classes.push('aspect-video h-full object-cover');
            }
            else {
                classes.push('h-auto');
            }
            return classes.join(' ');
        });
        return (_ctx, _cache) => {
            return (hasImage.value)
                ? (_openBlock(), _createElementBlock("figure", _hoisted_1, [
                    _createElementVNode("picture", {
                        class: _normalizeClass([_ctx.extraClasses, "block w-full overflow-hidden"])
                    }, [
                        (_ctx.image.srcset)
                            ? (_openBlock(), _createElementBlock("source", {
                                key: 0,
                                srcset: _ctx.image.srcset
                            }, null, 8, _hoisted_2))
                            : _createCommentVNode("", true),
                        _createElementVNode("img", {
                            alt: _ctx.image.alt,
                            src: _ctx.image.url,
                            class: _normalizeClass([imageClasses.value, "w-full scale-100 transform transition group-hover:scale-105"]),
                            loading: _ctx.lazyLoadImage
                        }, null, 10, _hoisted_3)
                    ], 2),
                    (displayCaption.value)
                        ? (_openBlock(), _createElementBlock("figcaption", {
                            key: 0,
                            class: _normalizeClass([{
                                    absolute: _ctx.isChildPageHeader,
                                    relative: !_ctx.isChildPageHeader,
                                }, "block font-serif text-[16px] leading-[30px]"])
                        }, _toDisplayString(_ctx.image.caption), 3))
                        : _createCommentVNode("", true)
                ]))
                : _createCommentVNode("", true);
        };
    }
});
