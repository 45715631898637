import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import InlineSvg from 'vue-inline-svg';
import { computed } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SvgIcon',
    props: {
        name: {
            type: String,
            required: true,
        },
    },
    setup(__props) {
        const props = __props;
        // Compute the icon source dynamically using a computed property
        const iconSrc = computed(() => {
            return require(`@icons/${props.name}.svg`);
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(InlineSvg), {
                class: "h-full w-full",
                src: iconSrc.value
            }, null, 8, ["src"]));
        };
    }
});
