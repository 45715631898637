import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import EmbedIframe from '@atoms/EmbedIframe.vue';
import ImageLandscape from '@atoms/ImageLandscape.vue';
import VideoLandscape from '@atoms/VideoLandscape.vue';
import { computed } from 'vue';
import { useMq } from 'vue3-mq';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HeroMedia',
    props: {
        image: {},
        mobileImage: {},
        videoEmbed: {},
        mobileVideoEmbed: {},
        videoFile: {},
        mobileVideoFile: {},
        isExhibition: { type: Boolean }
    },
    setup(__props) {
        const screen = useMq();
        const props = __props;
        //check if exhibition
        const isExhibition = computed(() => {
            return props.isExhibition || false;
        });
        //Embed
        const hasDesktopVideoEmbed = computed(() => {
            return props.videoEmbed && props.videoEmbed.length > 0;
        });
        const hasMobileVideoEmbed = computed(() => {
            return props.mobileVideoEmbed && props.mobileVideoEmbed.length > 0;
        });
        const desktopVideoEmbed = computed(() => {
            return props.videoEmbed;
        });
        const mobileVideoEmbed = computed(() => {
            return props.mobileVideoEmbed;
        });
        const embedClasses = computed(() => {
            if (isExhibition.value) {
                return [
                    'md:!aspect-video h-full w-full lg:w-auto lg:absolute lg:left-1/2 lg:top-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2',
                    hasMobileVideoEmbed.value ? '!aspect-square' : '',
                ].join(' ');
            }
            let classes = [];
            classes.push('relative w-full flex justify-center items-center overflow-hidden herovideoEmbed');
            if (hasMobileVideoEmbed.value) {
                classes.push('lg:!aspect-banner');
            }
            else {
                classes.push('!aspect-banner');
            }
            return classes.join(' ');
        });
        const embedWrapperClasses = computed(() => {
            if (!isExhibition.value)
                return '';
            return [
                'relative h-full w-full md:aspect-video',
                hasMobileVideoEmbed.value && screen.smMinus ? '!aspect-square' : '',
            ].join(' ');
        });
        //Video File
        const hasMobileVideoFile = computed(() => {
            return Object.keys(props.mobileVideoFile).length > 0;
        });
        const hasDesktopVideoFile = computed(() => {
            return Object.keys(props.videoFile).length > 0;
        });
        const desktopVideoFile = computed(() => {
            return props.videoFile;
        });
        const mobileVideoFile = computed(() => {
            return props.mobileVideoFile;
        });
        //Image
        const hasMobileImage = computed(() => {
            return !!props.mobileImage && Object.keys(props.mobileImage).length > 0;
        });
        const hasDesktopImage = computed(() => {
            return !!props.image && Object.keys(props.image).length > 0;
        });
        const desktopImage = computed(() => {
            return props.image;
        });
        const mobileImage = computed(() => {
            return props.mobileImage;
        });
        //mobile checks
        const hasMobileMedia = computed(() => {
            return (hasMobileImage.value ||
                hasMobileVideoEmbed.value ||
                hasMobileVideoFile.value);
        });
        const displayMobileMedia = computed(() => {
            if (isExhibition.value)
                return hasMobileMedia.value && screen.smMinus;
            return hasMobileMedia.value && screen.mdMinus;
        });
        return (_ctx, _cache) => {
            return (displayMobileMedia.value)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (hasMobileVideoFile.value)
                        ? (_openBlock(), _createBlock(VideoLandscape, {
                            key: 0,
                            video: mobileVideoFile.value,
                            removeMaxWidth: true,
                            controls: false,
                            autoplay: true,
                            muted: true,
                            loop: true
                        }, null, 8, ["video"]))
                        : (hasMobileVideoEmbed.value)
                            ? (_openBlock(), _createBlock(EmbedIframe, {
                                key: 1,
                                embed: mobileVideoEmbed.value,
                                embedClasses: embedClasses.value,
                                autoplay: true
                            }, null, 8, ["embed", "embedClasses"]))
                            : (hasMobileImage.value)
                                ? (_openBlock(), _createBlock(ImageLandscape, {
                                    key: 2,
                                    forcedRatio: true,
                                    image: mobileImage.value,
                                    lazyLoadImage: "eager",
                                    isChildPageHeader: isExhibition.value,
                                    class: _normalizeClass(isExhibition.value ? 'h-full' : ''),
                                    extraClasses: isExhibition.value ? 'h-full' : '',
                                    imageClasses: isExhibition.value ? 'h-full w-full object-cover' : ''
                                }, null, 8, ["image", "isChildPageHeader", "class", "extraClasses", "imageClasses"]))
                                : _createCommentVNode("", true)
                ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (hasDesktopVideoFile.value)
                        ? (_openBlock(), _createBlock(VideoLandscape, {
                            key: 0,
                            video: desktopVideoFile.value,
                            removeMaxWidth: true,
                            controls: false,
                            autoplay: true,
                            loop: true,
                            muted: true,
                            class: _normalizeClass(isExhibition.value ? 'aspect-video' : '')
                        }, null, 8, ["video", "class"]))
                        : (hasDesktopVideoEmbed.value)
                            ? (_openBlock(), _createBlock(EmbedIframe, {
                                key: 1,
                                embed: desktopVideoEmbed.value,
                                embedClasses: embedClasses.value,
                                "wrapper-classes": embedWrapperClasses.value,
                                autoplay: true
                            }, null, 8, ["embed", "embedClasses", "wrapper-classes"]))
                            : (hasDesktopImage.value)
                                ? (_openBlock(), _createBlock(ImageLandscape, {
                                    key: 2,
                                    forcedRatio: false,
                                    image: desktopImage.value,
                                    lazyLoadImage: "eager",
                                    isChildPageHeader: isExhibition.value,
                                    class: _normalizeClass(isExhibition.value ? 'h-full' : ''),
                                    extraClasses: isExhibition.value ? 'h-full' : '',
                                    imageClasses: isExhibition.value ? 'h-full w-full object-cover' : ''
                                }, null, 8, ["image", "isChildPageHeader", "class", "extraClasses", "imageClasses"]))
                                : _createCommentVNode("", true)
                ], 64));
        };
    }
});
