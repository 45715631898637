import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "flex flex-wrap items-center justify-end gap-2 text-right lg:shrink-0" };
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
    key: 0,
    class: "relative block"
};
const _hoisted_4 = ["onClick", "onKeyup"];
const _hoisted_5 = { class: "group" };
const _hoisted_6 = { class: "absolute bottom-0 right-0 top-0 flex shrink-0 flex-col items-center justify-center" };
const _hoisted_7 = {
    key: 1,
    class: "h-4 w-4 cursor-pointer lg:hidden"
};
import { ref, computed } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import { endOfWeek, startOfWeek, isToday } from 'date-fns';
import { hyphenate } from '@helpers/text';
import SvgIcon from '@atoms/SvgIcon.vue';
import { useMq } from 'vue3-mq';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DatePicker',
    setup(__props) {
        const screen = useMq();
        const today = new Date();
        const thisWeek = [
            startOfWeek(today, { weekStartsOn: 1 }),
            endOfWeek(today, { weekStartsOn: 1 }),
        ];
        const presetDates = ref([
            { label: 'Today', value: [today, today] },
            { label: 'This Week', value: thisWeek },
        ]);
        const date = ref();
        const formatDateFromUrl = computed(() => getDateFromUrl());
        const pickerActive = computed(() => dateText.value !== 'Select Date');
        const dateText = computed(() => {
            if (screen.mdMinus && formatDateFromUrl.value)
                return formatDateFromUrl.value;
            if (buttonIsActive('Today') ||
                buttonIsActive('This Week') ||
                !formatDateFromUrl.value) {
                return 'Select Date';
            }
            return formatDateFromUrl.value;
        });
        /**
         * Extracts and formats date(s) from the URL query parameters.
         * @returns {string | null} Formatted date string or null if not found.
         */
        const getDateFromUrl = () => {
            const queryParams = new URLSearchParams(window.location.search);
            const date = queryParams.get('date');
            const from = queryParams.get('from');
            const to = queryParams.get('to');
            if (date)
                return convertDateFormat(date);
            if (from && to)
                return `${convertDateFormat(from)} to ${convertDateFormat(to)}`;
            return null;
        };
        /**
         * Converts a date string from hyphen-separated to slash-separated format.
         * @param {string} date - The date string in 'YYYY-MM-DD' format.
         * @returns {string} The date string in 'YYYY/MM/DD' format.
         */
        const convertDateFormat = (date) => {
            return date.replace(/-/g, '/');
        };
        /**
         * Formats a pair of dates into strings in 'en-GB' locale format.
         * @param {[Date | string, Date | string]} date - Array containing two dates or date strings.
         * @returns {[string, string]} Array containing two formatted date strings.
         */
        const formatEventDate = (date) => {
            const fromDate = date[0] instanceof Date ? date[0].toLocaleDateString('en-GB') : date[0];
            const toDate = date[1] instanceof Date ? date[1].toLocaleDateString('en-GB') : date[1];
            return [fromDate, toDate];
        };
        /**
         * Handles date selection event and updates the URL with the selected date range.
         * @param {[Date, Date]} event - Array containing the selected start and end dates.
         */
        const handleDate = (event) => {
            const [fromDate, toDate] = formatEventDate(event);
            const newUrl = new URL(window.location.href);
            if (fromDate === toDate) {
                newUrl.searchParams.delete('from');
                newUrl.searchParams.delete('to');
                newUrl.searchParams.set('date', hyphenate(fromDate));
            }
            else {
                newUrl.searchParams.delete('date');
                newUrl.searchParams.set('from', hyphenate(fromDate));
                newUrl.searchParams.set('to', hyphenate(toDate));
            }
            updateUrl(newUrl);
        };
        /**
         * Updates the browser URL and reloads the page if necessary.
         * @param {URL} newUrl - The new URL to set.
         */
        const updateUrl = (newUrl) => {
            const currentUrl = new URL(window.location.href);
            if (currentUrl.toString() === newUrl.toString()) {
                newUrl.searchParams.delete('date');
                newUrl.searchParams.delete('from');
                newUrl.searchParams.delete('to');
            }
            window.history.pushState({}, '', newUrl);
            window.location.reload();
        };
        /**
         * Removes date-related query parameters from the URL and updates it.
         * @param {Event} event - The click event.
         */
        const removeDate = (event) => {
            event.stopPropagation();
            const newUrl = new URL(window.location.href);
            newUrl.searchParams.delete('date');
            newUrl.searchParams.delete('from');
            newUrl.searchParams.delete('to');
            updateUrl(newUrl);
        };
        /**
         * Checks if a date button with a specific label is currently active.
         * @param {string} label - The label of the date button ('Today' or 'This Week').
         * @returns {boolean} True if the button is active, false otherwise.
         */
        const buttonIsActive = (label) => {
            if (!formatDateFromUrl.value)
                return false;
            if (label === 'This Week' && formatDateFromUrl.value.includes('to')) {
                const thisWeekFormatted = formatEventDate([thisWeek[0], thisWeek[1]]);
                return thisWeekFormatted.join(' to ') === formatDateFromUrl.value;
            }
            if (label === 'Today' && !formatDateFromUrl.value.includes('to')) {
                const parsedDate = new Date(formatDateFromUrl.value.split('/').reverse().join('-'));
                return isToday(parsedDate);
            }
            return false;
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_unref(screen).lgPlus)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(presetDates.value, (date) => {
                        return (_openBlock(), _createElementBlock("button", {
                            onClick: _withModifiers(($event) => (handleDate([date.value[0], date.value[1]])), ["prevent"]),
                            class: _normalizeClass([
                                'flex items-center gap-2 px-2 py-1 transition-colors hover:border-green-50 hover:bg-green-50 hover:text-black',
                                buttonIsActive(date.label)
                                    ? 'border-2 border-green-50 pr-6 font-bold'
                                    : 'border border-grey-75',
                            ])
                        }, [
                            _createTextVNode(_toDisplayString(date.label) + " ", 1),
                            (buttonIsActive(date.label))
                                ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (i) => {
                                        return _createElementVNode("span", {
                                            key: i,
                                            class: "absolute block h-px w-3.5 shrink-0 grow-0 origin-center transform bg-black opacity-100 transition-all ease-in-out first:-rotate-45 last:rotate-45"
                                        });
                                    }), 64))
                                ]))
                                : _createCommentVNode("", true)
                        ], 10, _hoisted_2));
                    }), 256))
                    : _createCommentVNode("", true),
                _createVNode(_unref(VueDatePicker), {
                    modelValue: date.value,
                    "onUpdate:modelValue": [
                        _cache[0] || (_cache[0] = ($event) => ((date).value = $event)),
                        handleDate
                    ],
                    ref: "datepicker",
                    clearable: false,
                    "auto-apply": true,
                    "enable-time-picker": false,
                    "preset-dates": _unref(screen).mdMinus ? presetDates.value : [],
                    "month-change-on-scroll": false,
                    range: "",
                    format: "MM/dd/yyyy",
                    light: "",
                    placeholder: dateText.value,
                    "model-type": "dd.MM.yyyy",
                    "preview-format": "MM/dd/yyyy",
                    position: "right"
                }, {
                    "preset-date-range-button": _withCtx(({ label, value, presetDate }) => [
                        _createElementVNode("span", {
                            tabindex: 0,
                            role: "button",
                            onClick: ($event) => (presetDate(value)),
                            onKeyup: [
                                _withKeys(_withModifiers(($event) => (presetDate(value)), ["prevent"]), ["enter"]),
                                _withKeys(_withModifiers(($event) => (presetDate(value)), ["prevent"]), ["space"])
                            ]
                        }, _toDisplayString(label), 41, _hoisted_4)
                    ]),
                    "arrow-right": _withCtx(() => [
                        _createVNode(SvgIcon, {
                            class: "",
                            name: "arrow"
                        })
                    ]),
                    "arrow-left": _withCtx(() => [
                        _createVNode(SvgIcon, {
                            class: "origin-center rotate-180 transform",
                            name: "arrow"
                        })
                    ]),
                    "dp-input": _withCtx((value) => [
                        _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("button", {
                                class: _normalizeClass([
                                    'datePicker',
                                    pickerActive.value
                                        ? '!font-bold lg:!border-2 lg:!border-green-50 lg:!pr-8'
                                        : '',
                                ]),
                                type: "button"
                            }, _toDisplayString(dateText.value), 3),
                            _createElementVNode("div", _hoisted_6, [
                                (pickerActive.value)
                                    ? (_openBlock(), _createElementBlock("button", {
                                        key: 0,
                                        class: "relative flex h-full w-4 cursor-pointer items-center justify-center lg:w-9",
                                        onClick: removeDate
                                    }, [
                                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (i) => {
                                            return _createElementVNode("span", {
                                                key: i,
                                                class: "absolute block h-px w-3.5 shrink-0 grow-0 origin-center transform bg-black opacity-100 transition-all ease-in-out first:-rotate-45 last:rotate-45"
                                            });
                                        }), 64))
                                    ]))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_7, [
                                        _createVNode(SvgIcon, { name: "chevron-down" })
                                    ]))
                            ])
                        ])
                    ]),
                    _: 1
                }, 8, ["modelValue", "preset-dates", "placeholder"])
            ]));
        };
    }
});
