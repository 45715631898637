import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createVNode as _createVNode, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
    class: "!mt-10 flex flex-col gap-10 lg:mt-20",
    "data-archive-read-and-watch": ""
};
const _hoisted_2 = {
    key: 0,
    class: "container"
};
const _hoisted_3 = { class: "wysiwyg w-full lg:w-2/3" };
const _hoisted_4 = {
    key: 1,
    class: "container"
};
const _hoisted_5 = { class: "flex flex-wrap gap-2" };
const _hoisted_6 = ["href"];
const _hoisted_7 = { key: 0 };
const _hoisted_8 = {
    key: 0,
    class: "container mt-10 text-center"
};
const _hoisted_9 = { "data-quicklinks": "" };
import CollectionCarousel from '@molecules/CollectionCarousel.vue';
import FeaturedPosts from '@molecules/FeaturedPosts.vue';
import { reactive, defineAsyncComponent } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ArchiveReadAndWatch',
    props: {
        intro: {},
        featuredPosts: {},
        quickLinksSections: {},
        collections: {}
    },
    setup(__props) {
        const QuickLinks = defineAsyncComponent(() => import('@molecules/QuickLinks.vue'));
        const props = __props;
        const state = reactive({
            loadMore: false,
        });
        const first6Collections = props.collections.slice(0, 6);
        const restOfCollections = props.collections.slice(6);
        const collectionsLinks = first6Collections.map((collection) => {
            return {
                title: collection.title,
                url: collection.url,
            };
        });
        return (_ctx, _cache) => {
            const _directive_dompurify_html = _resolveDirective("dompurify-html");
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("section", _hoisted_1, [
                    (_ctx.intro)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _withDirectives(_createElementVNode("div", _hoisted_3, null, 512), [
                                [_directive_dompurify_html, _ctx.intro]
                            ])
                        ]))
                        : _createCommentVNode("", true),
                    (_unref(collectionsLinks))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(collectionsLinks), (collection) => {
                                    return (_openBlock(), _createElementBlock("a", {
                                        key: collection.url,
                                        href: collection.url,
                                        class: "flex cursor-pointer items-center gap-2 border border-grey-75 px-2 py-1 transition-colors hover:border-blue-50 hover:bg-blue-50 hover:text-black"
                                    }, _toDisplayString(collection.title), 9, _hoisted_6));
                                }), 128))
                            ])
                        ]))
                        : _createCommentVNode("", true),
                    _createVNode(FeaturedPosts, {
                        "featured-posts": _ctx.featuredPosts,
                        title: "Highlights"
                    }, null, 8, ["featured-posts"])
                ]),
                (_ctx.collections)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(first6Collections), (collection, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, null, [
                                collection
                                    ? (_openBlock(), _createBlock(CollectionCarousel, {
                                        key: 0,
                                        collection: collection,
                                        index: `${index}`
                                    }, null, 8, ["collection", "index"]))
                                    : _createCommentVNode("", true)
                            ], 64));
                        }), 256)),
                        _createVNode(_TransitionGroup, {
                            "enter-active-class": "slide-up__enter-active",
                            "enter-from-class": "slide-up__enter-from",
                            "enter-to-class": "slide-up__enter-to",
                            "leave-active-class": "slide-up__enter-active",
                            "leave-from-class": "slide-up__enter-to",
                            "leave-to-class": "slide-up__enter-from"
                        }, {
                            default: _withCtx(() => [
                                (_unref(restOfCollections) && !state.loadMore)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                        _createElementVNode("button", {
                                            class: "text-sm font-bold",
                                            onClick: _cache[0] || (_cache[0] = ($event) => (state.loadMore = true))
                                        }, " Load more ")
                                    ]))
                                    : _createCommentVNode("", true)
                            ]),
                            _: 1
                        }),
                        _createVNode(_TransitionGroup, {
                            "enter-active-class": "slide-up__enter-active",
                            "enter-from-class": "slide-up__enter-from",
                            "enter-to-class": "slide-up__enter-to"
                        }, {
                            default: _withCtx(() => [
                                (_unref(restOfCollections) && state.loadMore)
                                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(restOfCollections), (collection, index) => {
                                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                                            collection
                                                ? (_openBlock(), _createBlock(CollectionCarousel, {
                                                    key: 0,
                                                    collection: collection,
                                                    index: `${index}`,
                                                    style: _normalizeStyle({ transitionDelay: `${index * 200 + 500}ms` })
                                                }, null, 8, ["collection", "index", "style"]))
                                                : _createCommentVNode("", true)
                                        ], 64));
                                    }), 256))
                                    : _createCommentVNode("", true)
                            ]),
                            _: 1
                        })
                    ]))
                    : _createCommentVNode("", true),
                (_ctx.quickLinksSections)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.quickLinksSections, (quickLinks) => {
                        return (_openBlock(), _createElementBlock("section", _hoisted_9, [
                            _createVNode(_unref(QuickLinks), { quickLinks: quickLinks }, null, 8, ["quickLinks"])
                        ]));
                    }), 256))
                    : _createCommentVNode("", true)
            ], 64));
        };
    }
});
