import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "flex items-center justify-between gap-7 lg:items-end lg:gap-10" };
const _hoisted_2 = {
    key: 0,
    class: "mt-5 border-t border-black pt-6 lg:mt-7 lg:flex lg:items-center lg:justify-between lg:gap-10 lg:pt-10"
};
const _hoisted_3 = { class: "h3-alt mb-0 !text-sm" };
import { computed, defineAsyncComponent } from 'vue';
import { useMq } from 'vue3-mq';
import { decodeHTML } from '@helpers/text';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SectionHeader',
    props: {
        postType: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
            default: '',
        },
        button: {
            type: Object,
            required: false,
        },
        extraClasses: {
            type: String,
            required: false,
            default: '',
        },
        colorClasses: {
            type: String,
            required: false,
        },
        carouselTitle: {
            type: String,
            required: false,
            default: '',
        },
        innerContainerClasses: {
            type: String,
            required: false,
            default: 'container',
        },
        numberOfPosts: {
            type: Number,
            required: false,
            default: 0,
        },
        isSignpost: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(__props) {
        const screen = useMq();
        const ButtonWithArrow = defineAsyncComponent(() => import('@atoms/ButtonWithArrow.vue'));
        const SwiperNavigation = defineAsyncComponent(() => import('@molecules//SwiperNavigation.vue'));
        const displaySwiperNavigation = computed(() => {
            return screen.lgPlus && props.numberOfPosts > 3;
        });
        const props = __props;
        const computedClasses = computed(() => {
            const classes = [];
            // Add colorClasses if it's provided
            if (props.colorClasses) {
                classes.push(props.colorClasses);
            }
            else {
                if (props.postType === 'event') {
                    classes.push('bg-green');
                }
                if (props.postType === 'read_watch') {
                    classes.push('bg-blue');
                }
                if (!props.postType) {
                    classes.push('bg-transparent');
                }
            }
            // Add extra classes if provided
            if (props.extraClasses) {
                classes.push(...props.extraClasses.split(' '));
            }
            return classes;
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("header", {
                class: _normalizeClass([computedClasses.value, "py-7 lg:py-10"]),
                "data-section-header": ""
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(__props.innerContainerClasses)
                }, [
                    _createElementVNode("div", _hoisted_1, [
                        (__props.title)
                            ? (_openBlock(), _createElementBlock("h2", {
                                key: 0,
                                class: _normalizeClass([{ 'text-lg': __props.isSignpost }, "mb-0 leading-none"])
                            }, _toDisplayString(_unref(decodeHTML)(__props.title)), 3))
                            : _createCommentVNode("", true),
                        (__props.button && _unref(screen).mdMinus)
                            ? (_openBlock(), _createBlock(_unref(ButtonWithArrow), {
                                key: 1,
                                target: __props.button.target !== '' ? __props.button.target : undefined,
                                text: __props.button.title !== '' ? __props.button.title : undefined,
                                url: __props.button.url
                            }, null, 8, ["target", "text", "url"]))
                            : _createCommentVNode("", true),
                        (displaySwiperNavigation.value)
                            ? (_openBlock(), _createBlock(_unref(SwiperNavigation), {
                                key: 2,
                                carouselTitle: __props.carouselTitle,
                                class: "mb-3"
                            }, null, 8, ["carouselTitle"]))
                            : _createCommentVNode("", true)
                    ]),
                    (__props.subtitle)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createElementVNode("h3", _hoisted_3, _toDisplayString(__props.subtitle), 1),
                            (__props.button && _unref(screen).lgPlus)
                                ? (_openBlock(), _createBlock(_unref(ButtonWithArrow), {
                                    key: 0,
                                    target: __props.button.target !== '' ? __props.button.target : undefined,
                                    text: __props.button.title !== '' ? __props.button.title : undefined,
                                    url: __props.button.url
                                }, null, 8, ["target", "text", "url"]))
                                : _createCommentVNode("", true)
                        ]))
                        : _createCommentVNode("", true)
                ], 2)
            ], 2));
        };
    }
});
