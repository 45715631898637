if (import.meta.webpackHot) {
    import.meta.webpackHot.accept(console.error);
}
import { createApp } from 'vue';
import { Vue3Mq } from 'vue3-mq';
import { useFormTracking } from '@scripts/utils/FormTracking.ts';
import { vueDompurifyHTMLPlugin } from 'vue-dompurify-html';
// Initialize Vue app
const app = createApp({});
// Use VueDOMPurifyHTML plugin with correct options
app.use(vueDompurifyHTMLPlugin, {
    default: {
        ADD_ATTR: ['target', 'rel'],
    },
    namedConfigurations: {
        iframe: {
            ALLOWED_TAGS: ['iframe'],
            ADD_ATTR: [
                'allow',
                'allowfullscreen',
                'frameborder',
                'scrolling',
                'src',
                'title',
                'width',
                'height',
                'allow',
            ],
        },
    },
});
// Add Vue3Mq plugin
// Configure breakpoints
const breakpoints = {
    initial: 0,
    xxs: 320,
    xs: 400,
    sm: 480,
    md: 720,
    lg: 1024,
    xl: 1280,
    xxl: 1440,
    xxxl: 1920,
};
app.use(Vue3Mq, {
    breakpoints,
});
import ComponentLoader from '@organisms/ComponentLoader.vue';
import SiteHeader from '@organisms/SiteHeader.vue';
import HeroBlock from '@organisms/blocks/HeroBlock.vue';
import PageHeader from '@organisms/PageHeader.vue';
import ArchiveWhatsOn from '@organisms/ArchiveWhatsOn.vue';
import ArchiveCollection from '@organisms/ArchiveCollection.vue';
import ArchiveEventSeries from '@organisms/ArchiveEventSeries.vue';
import ArchiveEatAndDrink from '@organisms/ArchiveEatAndDrink.vue';
import ArchiveJobs from '@organisms/ArchiveJobs.vue';
import ArchiveReadAndWatch from '@organisms/ArchiveReadAndWatch.vue';
import SearchResults from '@organisms/SearchResults.vue';
import EventInfo from '@organisms/EventInfo.vue';
import VisitUsBlock from '@blocks/VisitUsBlock.vue';
app.component('ComponentLoader', ComponentLoader)
    .component('SiteHeader', SiteHeader)
    .component('HeroBlock', HeroBlock)
    .component('PageHeader', PageHeader)
    .component('ArchiveWhatsOn', ArchiveWhatsOn)
    .component('ArchiveCollection', ArchiveCollection)
    .component('ArchiveEventSeries', ArchiveEventSeries)
    .component('ArchiveEatAndDrink', ArchiveEatAndDrink)
    .component('ArchiveJobs', ArchiveJobs)
    .component('ArchiveReadAndWatch', ArchiveReadAndWatch)
    .component('SearchResults', SearchResults)
    .component('EventInfo', EventInfo)
    .component('VisitUsBlock', VisitUsBlock);
app.mount('#app');
const formBlocks = document.querySelectorAll('[data-form-block]');
const { handleFormBlock } = useFormTracking();
formBlocks.forEach((formBlock) => {
    handleFormBlock(formBlock);
});
