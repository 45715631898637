import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
    key: 2,
    class: "h-full w-full object-cover"
};
const _hoisted_2 = {
    key: 0,
    class: "mt-2 font-serif lg:mt-5"
};
import { computed } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'EmbedIframe',
    props: {
        embed: {},
        caption: {},
        embedClasses: {},
        wrapperClasses: {},
        autoplay: { type: Boolean },
        controls: { type: Boolean },
        loop: { type: Boolean },
        muted: { type: Boolean }
    },
    setup(__props) {
        const props = __props;
        const videoType = computed(() => {
            if (props.embed.includes('vimeo')) {
                return 'vimeo';
            }
            if (props.embed.includes('youtube')) {
                return 'youtube';
            }
            return 'iframe';
        });
        if (videoType.value === 'youtube') {
            import('youtube-video-element');
        }
        else if (videoType.value === 'vimeo') {
            import('vimeo-video-element');
        }
        return (_ctx, _cache) => {
            const _component_vimeo_video = _resolveComponent("vimeo-video");
            const _component_youtube_video = _resolveComponent("youtube-video");
            const _directive_dompurify_html = _resolveDirective("dompurify-html");
            return (_ctx.embed)
                ? (_openBlock(), _createElementBlock("figure", {
                    key: 0,
                    class: _normalizeClass(_ctx.wrapperClasses)
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass([_ctx.embedClasses, "aspect-video"])
                    }, [
                        (videoType.value === 'vimeo')
                            ? (_openBlock(), _createBlock(_component_vimeo_video, {
                                key: 0,
                                autoplay: _ctx.autoplay,
                                loop: _ctx.loop || _ctx.autoplay,
                                muted: _ctx.muted || _ctx.autoplay,
                                src: _ctx.embed,
                                controls: _ctx.controls,
                                class: "h-full w-full object-cover"
                            }, null, 8, ["autoplay", "loop", "muted", "src", "controls"]))
                            : (videoType.value === 'youtube')
                                ? (_openBlock(), _createBlock(_component_youtube_video, {
                                    key: 1,
                                    autoplay: _ctx.autoplay,
                                    loop: _ctx.loop || _ctx.autoplay,
                                    muted: _ctx.muted || _ctx.autoplay,
                                    src: _ctx.embed,
                                    controls: "",
                                    class: "h-full w-full object-cover"
                                }, null, 8, ["autoplay", "loop", "muted", "src"]))
                                : _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [
                                    [_directive_dompurify_html, _ctx.embed, "iframe"]
                                ])
                    ], 2),
                    (_ctx.caption)
                        ? (_openBlock(), _createElementBlock("figcaption", _hoisted_2, _toDisplayString(_ctx.caption), 1))
                        : _createCommentVNode("", true)
                ], 2))
                : _createCommentVNode("", true);
        };
    }
});
