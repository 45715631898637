import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "container flex flex-col gap-5" };
const _hoisted_2 = {
    key: 0,
    class: "!my-0 !font-jp"
};
const _hoisted_3 = {
    key: 1,
    class: "font-bold"
};
const _hoisted_4 = ["data-search-results", "data-search-term", "data-search-status"];
const _hoisted_5 = { key: 0 };
import { computed, defineAsyncComponent } from 'vue';
import { decodeHTML } from '@helpers/text';
import { useMq } from 'vue3-mq';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ParentPageHeader',
    props: {
        title: {
            type: String,
            required: true,
        },
        image: {
            type: Object,
            required: false,
        },
        japaneseTranslation: {
            type: String,
            required: false,
        },
        backgroundColor: {
            type: String,
            required: false,
        },
        totalSearchResults: {
            type: Number,
            required: false,
        },
        searchTerm: {
            type: String,
            required: false,
        },
        useJapaneseFont: {
            type: Boolean,
            required: false,
        },
    },
    setup(__props) {
        const ImageLandscape = defineAsyncComponent(() => import('@atoms/ImageLandscape.vue'));
        const props = __props;
        const screen = useMq();
        const forceRatioOnMobile = computed(() => {
            if (screen.mdMinus) {
                return true;
            }
            return false;
        });
        const assignedFont = computed(() => {
            return props.useJapaneseFont ? '!font-jp' : '';
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("div", {
                    class: _normalizeClass([__props.backgroundColor, "py-7 lg:py-10"])
                }, [
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("h1", {
                            class: _normalizeClass([assignedFont.value, "!mb-0"])
                        }, _toDisplayString(_unref(decodeHTML)(__props.title)), 3),
                        (__props.japaneseTranslation)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(__props.japaneseTranslation), 1))
                            : _createCommentVNode("", true),
                        (__props.searchTerm)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
                                _cache[0] || (_cache[0] = _createTextVNode(" Total results: ")),
                                _createElementVNode("span", {
                                    id: "gtmSearchResults",
                                    "data-search-results": __props.totalSearchResults,
                                    "data-search-term": __props.searchTerm,
                                    "data-search-status": __props.searchTerm && __props.totalSearchResults > 0
                                }, _toDisplayString(__props.totalSearchResults || '0'), 9, _hoisted_4)
                            ]))
                            : _createCommentVNode("", true)
                    ])
                ], 2),
                (__props.image && __props.image.url)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_unref(ImageLandscape), {
                            forcedRatio: forceRatioOnMobile.value,
                            image: __props.image,
                            lazyLoadImage: "eager"
                        }, null, 8, ["forcedRatio", "image"])
                    ]))
                    : _createCommentVNode("", true)
            ], 64));
        };
    }
});
