import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex w-full items-start justify-between gap-6 lg:gap-10" };
import DatePicker from '@atoms/DatePicker.vue';
import FilterInput from '@atoms/FilterInput.vue';
/* --------------------------
// States, stores and props
-------------------------- */
export default /*@__PURE__*/ _defineComponent({
    __name: 'PostsFilter',
    props: {
        filterOptions: {},
        showDate: { type: Boolean, default: true }
    },
    setup(__props) {
        /* --------------------------
        // Methods
        -------------------------- */
        const addFilterSelectionToUrl = (selectedValue) => {
            const newUrl = new URL(window.location.href);
            // Remove 'filtered' parameter if 'clear-filter' is selected
            if (selectedValue === 'clear-filter') {
                newUrl.searchParams.delete('filtered');
            }
            else {
                newUrl.searchParams.set('filtered', selectedValue);
            }
            // Remove '/page/' and a number if present
            const cleanedUrl = newUrl.toString().replace(/\/page\/\d+/g, '');
            window.history.pushState({}, '', cleanedUrl);
            window.location.reload();
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_ctx.filterOptions)
                    ? (_openBlock(), _createBlock(FilterInput, {
                        key: 0,
                        options: _ctx.filterOptions,
                        placeholder: "Event Type",
                        onSelectedValueChanged: addFilterSelectionToUrl
                    }, null, 8, ["options"]))
                    : _createCommentVNode("", true),
                (_ctx.showDate)
                    ? (_openBlock(), _createBlock(DatePicker, { key: 1 }))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
