import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, unref as _unref } from "vue";
const _hoisted_1 = {
    class: "!overflow-visible",
    "data-archive-event-series": ""
};
const _hoisted_2 = { class: "flex flex-col gap-10 lg:gap-20" };
const _hoisted_3 = {
    key: 0,
    class: "container"
};
const _hoisted_4 = { class: "wysiwyg w-full lg:w-2/3" };
const _hoisted_5 = { class: "container" };
const _hoisted_6 = {
    key: 1,
    class: "grid-layout container"
};
const _hoisted_7 = {
    key: 2,
    class: "container text-center"
};
const _hoisted_8 = { "data-quicklinks": "" };
import { computed, defineAsyncComponent, onMounted } from 'vue';
import PostCard from '@atoms/PostCard.vue';
import { scrollToSection } from '@helpers/scroll';
import PostsFilter from '@molecules/PostsFilter.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ArchiveEventSeries',
    props: {
        posts: {
            type: Object,
            required: false,
        },
        pagination: {
            type: Object,
            required: false,
        },
        quickLinksSections: {
            type: Object,
            required: false,
        },
        filterOptions: {
            type: Object,
            required: false,
        },
        additionalInformation: {
            type: String,
            required: false,
        },
    },
    setup(__props) {
        const ArchivePagination = defineAsyncComponent(() => import('@molecules/ArchivePagination.vue'));
        const QuickLinks = defineAsyncComponent(() => import('@molecules/QuickLinks.vue'));
        const postsExist = computed(() => {
            return props.posts && Object.keys(props.posts).length > 0;
        });
        const hasAdditionalInformation = computed(() => {
            return props.additionalInformation && props.additionalInformation.length;
        });
        onMounted(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const filteredContent = urlParams.get('filtered');
            if (filteredContent) {
                scrollToSection('[data-archive-event-series]');
            }
        });
        const props = __props;
        return (_ctx, _cache) => {
            const _directive_dompurify_html = _resolveDirective("dompurify-html");
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("section", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        (hasAdditionalInformation.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                _withDirectives(_createElementVNode("div", _hoisted_4, null, 512), [
                                    [_directive_dompurify_html, __props.additionalInformation]
                                ])
                            ]))
                            : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_5, [
                            _createVNode(PostsFilter, {
                                "filter-options": __props.filterOptions,
                                "show-date": false
                            }, null, 8, ["filter-options"])
                        ]),
                        (postsExist.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.posts, (post) => {
                                    return (_openBlock(), _createBlock(PostCard, {
                                        post: post,
                                        class: "!opacity-100"
                                    }, null, 8, ["post"]));
                                }), 256))
                            ]))
                            : (_openBlock(), _createElementBlock("h3", _hoisted_7, " Sorry, there are no posts to display for your filter selection. Please try another. ")),
                        (__props.pagination.pages.length > 1)
                            ? (_openBlock(), _createBlock(_unref(ArchivePagination), {
                                key: 3,
                                class: "container",
                                pagination: __props.pagination
                            }, null, 8, ["pagination"]))
                            : _createCommentVNode("", true)
                    ])
                ]),
                (__props.quickLinksSections)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(__props.quickLinksSections, (quickLinks) => {
                        return (_openBlock(), _createElementBlock("section", _hoisted_8, [
                            _createVNode(_unref(QuickLinks), { quickLinks: quickLinks }, null, 8, ["quickLinks"])
                        ]));
                    }), 256))
                    : _createCommentVNode("", true)
            ], 64));
        };
    }
});
