import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { defineAsyncComponent, shallowRef } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ComponentLoader',
    props: {
        name: {},
        loading: {},
        type: {}
    },
    setup(__props) {
        const props = __props;
        const dynamicComponent = shallowRef(null);
        // Dynamically load the component based on the prop name
        const importComponent = () => {
            if (props.type === 'block') {
                return defineAsyncComponent(() => import('@blocks/' + props.name + 'Block.vue'));
            }
            return defineAsyncComponent(() => import('@organisms/' + props.name + '.vue'));
        };
        // Set the dynamic component to the imported component
        dynamicComponent.value = importComponent();
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(dynamicComponent.value), null, {
                default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
            }));
        };
    }
});
