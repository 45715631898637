import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    "data-burger-button": "",
    class: "flex cursor-pointer items-center gap-2.5 font-bold",
    "aria-label": "Toggle navigation"
};
const _hoisted_2 = {
    key: 0,
    class: "hidden lg:block"
};
import BurgerLines from '@atoms/BurgerLines.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BurgerButton',
    props: {
        navOpen: {
            type: Boolean,
            required: false,
            default: false,
        },
        searchOpen: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return (!__props.searchOpen)
                ? (_openBlock(), _createElementBlock("button", _hoisted_1, [
                    (!__props.navOpen)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_2, " Menu "))
                        : _createCommentVNode("", true),
                    _createVNode(BurgerLines, { active: __props.navOpen }, null, 8, ["active"])
                ]))
                : _createCommentVNode("", true);
        };
    }
});
