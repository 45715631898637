import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
    class: "content-section",
    "data-archive-restaurants": ""
};
const _hoisted_2 = {
    key: 0,
    class: "container grid-layout"
};
const _hoisted_3 = {
    key: 0,
    class: "wysiwyg lg:col-span-2"
};
const _hoisted_4 = { key: 1 };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { "data-quicklinks": "" };
import { computed, defineAsyncComponent } from 'vue';
import ButtonWithArrow from '@atoms/ButtonWithArrow.vue';
import SignpostsBlock from '@organisms/blocks/SignpostsBlock.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ArchiveEatAndDrink',
    props: {
        intro: {
            type: String,
            required: true,
        },
        quickLinksSections: {
            type: Object,
            required: false,
        },
        posts: {
            type: Object,
            required: false,
        },
    },
    setup(__props) {
        const QuickLinks = defineAsyncComponent(() => import('@molecules/QuickLinks.vue'));
        const props = __props;
        const hasPosts = computed(() => props.posts && Object.keys(props.posts).length > 0);
        const colorClasses = (index) => {
            if (index % 2) {
                return 'bg-transparent';
            }
            return 'bg-yellow-50';
        };
        return (_ctx, _cache) => {
            const _directive_dompurify_html = _resolveDirective("dompurify-html");
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("section", _hoisted_1, [
                    (__props.intro || hasPosts.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            (__props.intro)
                                ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, null, 512)), [
                                    [_directive_dompurify_html, __props.intro]
                                ])
                                : _createCommentVNode("", true),
                            (hasPosts.value)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.posts, (post, index) => {
                                        return (_openBlock(), _createBlock(ButtonWithArrow, {
                                            key: `hero-button-${index}`,
                                            target: post.target,
                                            text: post.title,
                                            url: post.url,
                                            class: "pb-4"
                                        }, null, 8, ["target", "text", "url"]));
                                    }), 128))
                                ]))
                                : _createCommentVNode("", true)
                        ]))
                        : _createCommentVNode("", true)
                ]),
                (hasPosts.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.posts, (singlePost, index) => {
                            return (_openBlock(), _createBlock(SignpostsBlock, {
                                colorClasses: colorClasses(index),
                                paragraph: singlePost.excerpt,
                                posts: [singlePost],
                                title: singlePost.title
                            }, null, 8, ["colorClasses", "paragraph", "posts", "title"]));
                        }), 256))
                    ]))
                    : _createCommentVNode("", true),
                (__props.quickLinksSections)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(__props.quickLinksSections, (quickLinks) => {
                        return (_openBlock(), _createElementBlock("section", _hoisted_6, [
                            _createVNode(_unref(QuickLinks), { quickLinks: quickLinks }, null, 8, ["quickLinks"])
                        ]));
                    }), 256))
                    : _createCommentVNode("", true)
            ], 64));
        };
    }
});
