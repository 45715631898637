import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue";
const _hoisted_1 = {
    "data-search-results": "",
    class: "!mt-0"
};
const _hoisted_2 = { class: "container mb-10 flex items-center justify-start overflow-x-auto whitespace-nowrap pb-4" };
const _hoisted_3 = { class: "container flex flex-col gap-10 lg:gap-20" };
const _hoisted_4 = {
    key: 0,
    class: "grid-layout"
};
const _hoisted_5 = {
    key: 1,
    class: "grid-layout"
};
const _hoisted_6 = { class: "col-span-2 flex flex-col gap-10" };
const _hoisted_7 = {
    key: 0,
    class: "justify-left flex w-full flex-col flex-wrap gap-x-10 gap-y-5 sm:flex-row"
};
const _hoisted_8 = { "data-quicklinks": "" };
import { computed, defineAsyncComponent } from 'vue';
import PostCard from '@atoms/PostCard.vue';
import RadioButtons from '@molecules/RadioButtons.vue';
import { convertToSearchTerm, convertToTitleCase } from '@helpers/text';
import ButtonWithArrow from '@atoms/ButtonWithArrow.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SearchResults',
    props: {
        posts: {
            type: Object,
            required: false,
        },
        pagination: {
            type: Object,
            required: false,
        },
        quickLinksSections: {
            type: Object,
            required: false,
        },
        suggestedSearches: {
            type: Object,
            required: false,
        },
    },
    setup(__props) {
        const ArchivePagination = defineAsyncComponent(() => import('@molecules/ArchivePagination.vue'));
        const QuickLinks = defineAsyncComponent(() => import('@molecules/QuickLinks.vue'));
        const addFilterSelectionToUrl = (selectedValue) => {
            const newUrl = new URL(window.location.href);
            // Remove 'filtered' parameter if 'clear-filter' is selected
            if (selectedValue === 'clear-filter') {
                newUrl.searchParams.delete('filtered');
            }
            else {
                newUrl.searchParams.set('filtered', selectedValue);
            }
            // Remove '/page/' and a number if present
            const cleanedUrl = newUrl.toString().replace(/\/page\/\d+/g, '');
            window.history.pushState({}, '', cleanedUrl);
            window.location.reload();
        };
        //filterOptions array with name and slug for each option
        const filterOptions = {
            all: {
                name: 'All',
                slug: 'all',
            },
            whatsOn: {
                name: "What's On",
                slug: 'whats-on',
            },
            readWatch: {
                name: 'Read & Watch',
                slug: 'read-and-watch',
            },
            pastEvents: {
                name: 'Past Events',
                slug: 'past-events',
            },
            pages: {
                name: 'Pages',
                slug: 'pages',
            },
        };
        const hasSuggestedSearches = computed(() => {
            return (props.suggestedSearches &&
                Object.keys(props.suggestedSearches).length > 0);
        });
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("section", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(RadioButtons, {
                            options: filterOptions,
                            title: "Post type",
                            onSelectedValueChanged: addFilterSelectionToUrl
                        })
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                        (__props.posts.length > 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.posts, (post) => {
                                    return (_openBlock(), _createBlock(PostCard, {
                                        post: post,
                                        class: "!opacity-100"
                                    }, null, 8, ["post"]));
                                }), 256))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                _createElementVNode("div", _hoisted_6, [
                                    _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "h3 !mb-0" }, " Sorry, there are no matching results. Please try again or try one of our popular searches below. ", -1)),
                                    (hasSuggestedSearches.value)
                                        ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.suggestedSearches, (searchTerm) => {
                                                return (_openBlock(), _createElementBlock("li", null, [
                                                    _createVNode(ButtonWithArrow, {
                                                        text: _unref(convertToTitleCase)(searchTerm.query),
                                                        url: `/search/${_unref(convertToSearchTerm)(searchTerm.query)}`
                                                    }, null, 8, ["text", "url"])
                                                ]));
                                            }), 256))
                                        ]))
                                        : _createCommentVNode("", true)
                                ])
                            ])),
                        (__props.pagination.pages.length > 1)
                            ? (_openBlock(), _createBlock(_unref(ArchivePagination), {
                                key: 2,
                                pagination: __props.pagination
                            }, null, 8, ["pagination"]))
                            : _createCommentVNode("", true)
                    ])
                ]),
                (__props.quickLinksSections)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(__props.quickLinksSections, (quickLinks) => {
                        return (_openBlock(), _createElementBlock("section", _hoisted_8, [
                            _createVNode(_unref(QuickLinks), { quickLinks: quickLinks }, null, 8, ["quickLinks"])
                        ]));
                    }), 256))
                    : _createCommentVNode("", true)
            ], 64));
        };
    }
});
