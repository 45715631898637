import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = ["id"];
const _hoisted_2 = {
    key: 0,
    class: "relative lg:order-first"
};
const _hoisted_3 = {
    key: 2,
    class: "container"
};
const _hoisted_4 = { class: "flex flex-wrap items-center gap-10 border-b border-black py-10 lg:justify-center lg:pt-0" };
import ButtonWithArrow from '@atoms/ButtonWithArrow.vue';
import HeroMedia from '@molecules/HeroMedia.vue';
import { computed } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HeroBlock',
    props: {
        isHomepage: { type: Boolean },
        title: {},
        subTitle: {},
        buttons: {},
        image: {},
        mobileImage: {},
        videoEmbed: {},
        mobileVideoEmbed: {},
        videoFile: {},
        mobileVideoFile: {},
        anchor: {}
    },
    setup(__props) {
        const props = __props;
        /*
         * Computed
         */
        const displayMedia = computed(() => {
            return (props.image.url?.length > 0 ||
                props.videoEmbed.length > 0 ||
                props.videoFile.url?.length > 0);
        });
        const hasSubTitle = computed(() => {
            return props.subTitle.length > 0;
        });
        const displayTitleElement = computed(() => props.isHomepage ? 'h1' : 'h2');
        const displaySubTitleElement = computed(() => props.isHomepage ? 'h2' : 'h3');
        /*
         * Method
         */
        const determineIcon = (buttonType) => {
            return buttonType === 'anchor' ? 'chevron' : 'arrow';
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("section", {
                id: _ctx.anchor,
                class: "!my-0 grid grid-cols-1",
                "data-hero-block": ""
            }, [
                (displayMedia.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        (displayMedia.value)
                            ? (_openBlock(), _createBlock(HeroMedia, {
                                key: 0,
                                image: _ctx.image,
                                mobileImage: _ctx.mobileImage,
                                videoEmbed: _ctx.videoEmbed,
                                mobileVideoEmbed: _ctx.mobileVideoEmbed,
                                videoFile: _ctx.videoFile,
                                mobileVideoFile: _ctx.mobileVideoFile
                            }, null, 8, ["image", "mobileImage", "videoEmbed", "mobileVideoEmbed", "videoFile", "mobileVideoFile"]))
                            : _createCommentVNode("", true)
                    ]))
                    : _createCommentVNode("", true),
                (_ctx.title)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass([{ 'order-first pb-8 lg:order-none': _ctx.isHomepage }, "container pt-10 lg:pb-4 lg:text-center"])
                    }, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(displayTitleElement.value), {
                            class: _normalizeClass([{
                                    'text-lg': displayTitleElement.value === 'h2',
                                    'text-xl': displayTitleElement.value === 'h1',
                                }, "mb-0"])
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.title), 1)
                            ]),
                            _: 1
                        }, 8, ["class"])),
                        (hasSubTitle.value)
                            ? (_openBlock(), _createBlock(_resolveDynamicComponent(displaySubTitleElement.value), {
                                key: 0,
                                class: "mt-2 text-base"
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.subTitle), 1)
                                ]),
                                _: 1
                            }))
                            : _createCommentVNode("", true)
                    ], 2))
                    : _createCommentVNode("", true),
                (_ctx.buttons)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button, index) => {
                                return (_openBlock(), _createBlock(ButtonWithArrow, {
                                    key: `hero-button-${index}`,
                                    target: button.target,
                                    text: button.title,
                                    url: button.url,
                                    icon: determineIcon(button.type)
                                }, null, 8, ["target", "text", "url", "icon"]));
                            }), 128))
                        ])
                    ]))
                    : _createCommentVNode("", true)
            ], 8, _hoisted_1));
        };
    }
});
