import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "h-5 w-[35px] shrink-0 grow-0 overflow-hidden" };
import SvgIcon from '@atoms/SvgIcon.vue';
import { decodeHTML } from '@helpers/text';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ButtonWithArrow',
    props: {
        url: {
            type: String,
            required: false,
        },
        text: {
            type: String,
            required: false,
            default: 'Read more',
        },
        target: {
            type: String,
            required: false,
            default: '_self',
        },
        icon: {
            type: String,
            required: false,
            default: 'arrow',
        },
    },
    setup(__props) {
        const props = __props;
        const handleButtonClick = () => {
            if (props.url === undefined)
                return;
            if (props.url.startsWith('#')) {
                const targetElement = document.querySelector(props.url);
                if (targetElement) {
                    const headerHeight = document.querySelector('[data-site-header]').offsetHeight + 40;
                    const offset = targetElement.getBoundingClientRect().top +
                        window.scrollY -
                        headerHeight;
                    window.scrollTo({ top: offset, behavior: 'smooth' });
                }
            }
            else {
                window.open(props.url, props.target || '_self');
            }
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(__props.url ? 'a' : 'button'), {
                "data-button-with-arrow": "",
                class: "gtmButtonWithArrow group flex max-w-max shrink-0 cursor-pointer items-center justify-start gap-1.5 text-sm font-bold text-black hover:text-black",
                href: __props.url,
                target: __props.url ? __props.target || '_self' : null,
                rel: __props.target === '_blank' ? 'noopener noreferrer' : null,
                onClick: _withModifiers(handleButtonClick, ["prevent"])
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(decodeHTML)(__props.text) || 'Read more') + " ", 1),
                    _createElementVNode("span", _hoisted_1, [
                        (__props.icon === 'chevron')
                            ? (_openBlock(), _createBlock(SvgIcon, {
                                key: 0,
                                class: "rotate-90 transform transition-transform group-hover:rotate-0",
                                name: "chevron"
                            }))
                            : (_openBlock(), _createBlock(SvgIcon, {
                                key: 1,
                                class: "-translate-x-2 transform transition-transform group-hover:translate-x-0",
                                name: "arrow"
                            }))
                    ])
                ]),
                _: 1
            }, 8, ["href", "target", "rel"]));
        };
    }
});
